export const mode = "PROD";

export const URIs = {
  PORTAL: "https://portal.inextlabs.ai",
  B2C_LOGIN: "https://portal.inextlabs.ai",
  B2C_POST_LOGOUT: "https://portal.inextlabs.ai",
  B2C_CHANGE_PASSWORD:
    "https://inextlabsidentity.b2clogin.com/inextlabsidentity.onmicrosoft.com/oauth2/v2.0/authorize",
  API_NODE: "https://inextlabs-services-node.azurewebsites.net", // Node JS APIs
  AI_ASSISTANT:
    "https://portalv2-staging-oai.openai.azure.com/openai/deployments/gpt-35-turbo",
  AI_CONVERSATION_SUMMARY:
    "https://portalv2-staging-oai.openai.azure.com/openai/deployments/gpt-35-turbo",
  API_DOCUMENTATION: "https://inextlabs.stoplight.io/",
  DOCUMENTATIONS: "https://docs.inextlabs.ai",
  API_KEY_FUNCTION_APP: "https://generateapimsubscription.azurewebsites.net",
  BROADCAST_FUNCTION_APP:
    "https://inextbroadcast-prod-asp.azurewebsites.net{apiEndPoint}?code=hN59BKxxErnH1tJDER9-qA4BliAPtfZ_XWChGEivcIqSAzFuZyXU9w==",
  BROADCAST_FUNCTION_APP_V3:
    "https://broadcast-v3-fa.azurewebsites.net{apiEndPoint}?code=aH32uQ2Rj1Xop0F8SriytLVrbDgJ0g05-NqDaG2b98OUAzFuZ2ZaGQ==",
  BROADCAST_FUNCTION_APP_V4:
    "https://broadcast-v4-fa.azurewebsites.net{apiEndPoint}?code=tS3Lt32RcM4fu9BgLc9Lu3i48OhnG1VeyfEN5oCy7OD2AzFueml0sA==",
  HELP_AND_SUPPORT_DOCUMENTATION: "https://docs.inextlabs.ai",
  BOT_KNOWLEDGEBASE_FUNCTION_APP:
    "https://qna-llm-v2-prod-fa.azurewebsites.net{apiEndPoint}?code=QY_5iBcq0B7gjyG8ULBVNFd2P3cW2x0mlKBI-XpW8Jh-AzFutgE67A==",
  DIALOG360: "https://hub.360dialog.com",
  WABA: "https://business.facebook.com",
  FEEDBACK_LOGIC_APP:
    "https://prod-29.southeastasia.logic.azure.com:443/workflows/7c83fcff43de419aae394c7ab66b58cf/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=IpAP_3WG-zxNSBGFl1CSwFjP25kfqPesWh14w5_Szvg",

  GOOGLEMAP: "https://maps.google.com",
  CHANNEL_REQUEST:
    "https://prod-26.southeastasia.logic.azure.com:443/workflows/e6e5acfeaf4c4b7c8ecbc782cc365b8f/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=5aB33bzbmOQFzlqdwBvWM6BEYOsaVxedXipshphxiHU", //Logic App
  APP_INSIGHTS_INSTRUMENTATION_KEY: "5fbd25ce-0705-4cf7-b962-9fe6c6eaa53d",
  AES_KEY: "iNextSaltKeyP@$$w0rd1234",
  WOOCOMMERCE_REST_DOCUMENTATION: "https://woocommerce.github.io",
  WEBSITE: "https://inextlabs.ai",
  FACEBOOK: "https://www.facebook.com",
};
